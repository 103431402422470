import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { visuallyHidden } from "@mui/utils";
import { ApiInstance } from "../../../api/api";
import {
  DeviceStackDataType,
  DistroNameDataType,
} from "../../../types/buildingTypes";
import ARPEntriesSearch from "../ARPEntriesSearch";
import { useParams } from "react-router-dom";
import useRegionQueryParam from "../../../hooks/useRegionQueryParam";
import useSiteQueryParam from "../../../hooks/useSiteQueryParam";
import TablePlaceholder from "../../placeholders/TablePlaceholder";
import DeviceProcessingError from "../../placeholders/DeviceProcessingError";
import useVendorQueryParam from "../../../hooks/useVendorQueryParam";
import useBuildingQueryParam from "../../../hooks/useBuildingQueryParam";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number,
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadStackCell {
  disablePadding: boolean;
  id: keyof DeviceStackDataType;
  label: string;
  numeric: boolean;
}

const headStackCells: readonly HeadStackCell[] = [
  {
    id: "switch_number",
    numeric: false,
    disablePadding: false,
    label: "Switch number",
  },
  {
    id: "role",
    numeric: false,
    disablePadding: false,
    label: "Role",
  },
  {
    id: "state",
    numeric: false,
    disablePadding: false,
    label: "State",
  },
  {
    id: "model",
    numeric: false,
    disablePadding: false,
    label: "Model",
  },
  {
    id: "uptime",
    numeric: false,
    disablePadding: false,
    label: "Uptime",
  },
  {
    id: "available_power",
    numeric: false,
    disablePadding: false,
    label: "Available power",
  },
];

interface StackTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof DeviceStackDataType,
  ) => void;
  order: Order;
  orderBy: string;
}

function StackTableHead(props: StackTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof DeviceStackDataType) =>
    (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headStackCells.map((HeadStackCell) => (
          <TableCell
            key={HeadStackCell.id}
            align={HeadStackCell.numeric ? "right" : "left"}
            padding={HeadStackCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === HeadStackCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === HeadStackCell.id}
              direction={orderBy === HeadStackCell.id ? order : "asc"}
              onClick={createSortHandler(HeadStackCell.id)}
            >
              {HeadStackCell.label}
              {orderBy === HeadStackCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface TableToolbarProps {
  title: string;
  entity: string;
  search: boolean;
}

function TableToolbar(props: TableToolbarProps) {
  const { title, entity, search } = props;

  return (
    <Toolbar>
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h5"
        id="tableTitle"
        component="div"
      >
        {title}: {entity}
      </Typography>
      {search && <ARPEntriesSearch />}
    </Toolbar>
  );
}

interface StackTypeTableProps {
  device: string;
}

export default function StackTable(props: StackTypeTableProps) {
  const { device } = props;
  const { distro, access_switch } = useParams<DistroNameDataType>();
  const { selectedBuilding } = useBuildingQueryParam();
  const { selectedRegion } = useRegionQueryParam();
  const { selectedVendor } = useVendorQueryParam();
  const { selectedSite } = useSiteQueryParam();
  const [isLoading, setIsLoading] = React.useState(false);
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] =
    React.useState<keyof DeviceStackDataType>("model");
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [arpEntries, setStackEntries] = React.useState<DeviceStackDataType[]>(
    [],
  );
  const [apiErrorFlag, setApiErrorFlag] = React.useState<Boolean>(false);
  const [legacyDeviceError, setLegacyDeviceError] = React.useState(false);

  const getStack = async () => {
    setIsLoading(true);
    const legacyDeviceResponse: string =
      "Invalid input detected at '^' marker.";

    if (device === "distro") {
      try {
        const distro_stack = await ApiInstance.getDistroStack(
          distro,
          selectedVendor,
          selectedBuilding,
          selectedRegion,
        );
        setStackEntries(distro_stack.stack_data);
      } catch (e: any) {
        if (e.response.data.detail === legacyDeviceResponse) {
          setLegacyDeviceError(true);
        }
        setApiErrorFlag(true);
      }
    } else {
      try {
        const switch_stack = await ApiInstance.getSwitchStack(
          access_switch,
          selectedVendor,
          selectedBuilding,
          selectedRegion,
        );
        setStackEntries(switch_stack.stack_data);
      } catch (e: any) {
        if (e.response.data.detail === legacyDeviceResponse) {
          setLegacyDeviceError(true);
        }
        setApiErrorFlag(true);
      }
    }

    setIsLoading(false);
  };

  React.useEffect(() => {
    getStack();
  }, []);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof DeviceStackDataType,
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - arpEntries.length) : 0;

  const stackRows = React.useMemo(
    () =>
      stableSort(arpEntries, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, arpEntries],
  );

  const column_labels = headStackCells.map((item) => item.label);

  return (
    <Box sx={{ width: "100%", margin: "5pt" }}>
      <Box sx={{ width: "100%", margin: "5pt" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableToolbar
            title={`Stack for selected ${device}`}
            entity={`${selectedSite}`}
            search={false}
          />
          {isLoading ? (
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <TablePlaceholder rows={5} columns={column_labels} />
            </Box>
          ) : (
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
              >
                <StackTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {stackRows.map((row, index) => {
                    const labelId = `stack-entries-table-checkbox-${index}`;

                    return (
                      <TableRow hover sx={{ cursor: "pointer" }}>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="normal"
                        >
                          {row.switch_number}
                        </TableCell>
                        <TableCell>{row.role}</TableCell>
                        <TableCell>{row.state}</TableCell>
                        <TableCell>{row.model}</TableCell>
                        <TableCell>{row.uptime}</TableCell>
                        <TableCell>{row.available_power}</TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                  {apiErrorFlag && (
                    <TableRow>
                      <TableCell colSpan={8}>
                        <DeviceProcessingError
                          legacyDeviceError={legacyDeviceError}
                        />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <TablePagination
            rowsPerPageOptions={[
              10,
              15,
              20,
              { value: arpEntries.length, label: "All" },
            ]}
            component="div"
            count={arpEntries.length}
            rowsPerPage={rowsPerPage}
            labelRowsPerPage="Rows per table:"
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        <FormControlLabel
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label="Dense padding"
        />
      </Box>
    </Box>
  );
}
