import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { green, red, amber, blue, blueGrey } from "@mui/material/colors";
import Typography from "@mui/material/Typography";
import { ApiInstance } from "../../../api/api";
import { DeviceSwitchPortsDataType } from "../../../types/buildingTypes";
import {
  PortDataType,
  PerformPortBounceRequestType,
} from "../../../types/types";
import IconButton from "@mui/material/IconButton";

const buttonStyles = {
  padding: "8px 20px",
  textTransform: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontWeight: "bold",
  color: "white",
};

const cancelButtonStyles = {
  ...buttonStyles,
  backgroundColor: blueGrey[500],
  "&:hover": { backgroundColor: blueGrey[700] },
};

const confirmButtonStyles = {
  ...buttonStyles,
  backgroundColor: red[500],
  "&:hover": { backgroundColor: red[700] },
};

interface PortBounceActionProps {
  open: boolean;
  onClose: () => void;
  ip_address: string | undefined;
  device_type: string | null;
  region: string | null;
  building_code: string | null;
  unit: string | undefined;
  unit_name: string | null;
  selectedPorts: DeviceSwitchPortsDataType[];
}

type DialogState = "default" | "loading" | "success" | "error";

const PortBounceAction: React.FC<PortBounceActionProps> = ({
  open,
  onClose,
  ip_address,
  device_type,
  region,
  building_code,
  unit,
  unit_name,
  selectedPorts,
}) => {
  const DEFAULT_LOADING_TEXT = "Please wait while we process your request...";
  const DEFAULT_ERROR_TEXT = "Something went wrong. Please try again later.";

  const titleMap: Record<DialogState, string> = {
    default: "Are you sure you want to bounce the following ports?",
    loading: "Bounce action",
    success: "Bounce action",
    error: "Bounce action",
  };

  const backgroundColorMap: Record<DialogState, string> = {
    default: amber[800],
    loading: blue[600],
    success: green[500],
    error: red[500],
  };

  const [dialogState, setDialogState] = useState<DialogState>("default");
  const [loadingText, setLoadingText] = useState(DEFAULT_LOADING_TEXT);
  const [errorText, setErrorText] = useState(DEFAULT_ERROR_TEXT);

  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        setDialogState("default");
        setLoadingText(DEFAULT_LOADING_TEXT);
        setErrorText(DEFAULT_ERROR_TEXT);
      }, 100);
    }
  }, [open]);

  const handleConfirm = async () => {
    setDialogState("loading");
    setLoadingText("Please wait while we process your request...");
    try {
      const ports: PortDataType[] = selectedPorts.map(({ port, vlan }) => ({
        port,
        vlan,
      }));
      const payload: PerformPortBounceRequestType = {
        ip_address: ip_address,
        device_type: device_type,
        region: region,
        building_code: building_code,
        unit: unit,
        unit_name: unit_name,
        ports: ports,
      };

      await ApiInstance.performPortBounce(payload);
      setDialogState("success");
    } catch (error) {
      setDialogState("error");
      setErrorText(
        "Oops! We couldn't process your request. Please try again later, or contact the administrator for further assistance.",
      );
    }
  };

  const getDialogTitle = () => titleMap[dialogState];
  const getTitleBackgroundColor = () => backgroundColorMap[dialogState];

  const gridStyle: React.CSSProperties = {
    display: "grid",
    gridTemplateColumns: selectedPorts.length > 1 ? "1fr 1fr" : "1fr",
    gap: "12px 30px",
    textAlign: "left",
    width: "fit-content",
    margin: "16px auto 0",
  };

  const DialogContentLoading = () => (
    <>
      <CircularProgress sx={{ marginTop: 1 }} size={50} />
      <Typography variant="body1">{loadingText}</Typography>
    </>
  );

  const DialogContentSuccess = () => (
    <>
      <CheckCircleIcon sx={{ fontSize: 50, color: green[500], marginTop: 1 }} />
      <Typography variant="body1">
        Your action was successful! Execution on the network side will finish in
        a minute or two.
      </Typography>
    </>
  );

  const DialogContentError = () => (
    <>
      <ErrorIcon sx={{ fontSize: 50, color: red[500], marginTop: 1 }} />
      <Typography variant="body1">{errorText}</Typography>
    </>
  );

  const DialogContentDefault = () => (
    <>
      <Box sx={gridStyle}>
        {selectedPorts.map((entity, index) => (
          <Box key={index}>
            <Typography variant="body1">
              <Box component="span" fontWeight="bold">
                {entity.port}
              </Box>{" "}
              (VLAN: {entity.vlan})
            </Typography>
          </Box>
        ))}
      </Box>
    </>
  );

  const getDialogContent = () => {
    switch (dialogState) {
      case "loading":
        return <DialogContentLoading />;
      case "success":
        return <DialogContentSuccess />;
      case "error":
        return <DialogContentError />;
      default:
        return <DialogContentDefault />;
    }
  };

  const showCloseIcon = dialogState !== "loading";
  const showDialogActions = dialogState === "default";

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          minWidth: 600,
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.15)",
          borderRadius: "12px",
        },
      }}
      sx={{
        "& .MuiDialog-paper": {
          maxWidth: "600px",
          width: "100%",
        },
      }}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-content"
    >
      <DialogTitle
        id="dialog-title"
        sx={{
          backgroundColor: getTitleBackgroundColor(),
          color: "white",
          fontSize: "1.2rem",
          textAlign: "center",
          fontWeight: "bold",
          padding: "15px",
        }}
      >
        {getDialogTitle()}
        {showCloseIcon && (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "white",
              "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.1)" },
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent
        id="dialog-content"
        sx={{
          padding: "10px",
          textAlign: "center",
          fontSize: "1rem",
          color: "#333",
        }}
      >
        {getDialogContent()}
      </DialogContent>
      {showDialogActions && (
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "30px",
            padding: "16px",
          }}
        >
          <Button
            onClick={onClose}
            color="secondary"
            variant="contained"
            sx={cancelButtonStyles}
          >
            <CloseIcon sx={{ marginRight: "8px" }} />
            Cancel
          </Button>
          <Button
            onClick={handleConfirm}
            color="primary"
            variant="contained"
            sx={confirmButtonStyles}
          >
            <CheckIcon sx={{ marginRight: "8px" }} />
            Confirm
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default PortBounceAction;
