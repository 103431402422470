export type ActionAnalyticDataType = {
  user_action: string;
  action_count: number;
};

export type AnalyticActionResponseListType = {
  actions: ActionAnalyticDataType[];
};

export type MonthlyAnalyticDataType = {
  month: string;
  action_counts: {
    action: string;
    action_count: number;
  }[];
};

export type DataSetType = {
  [action: string]: number[];
};

export enum DurationEnum {
  Day = "Day",
  Week = "Week",
  Month = "Month",
  Year = "Year",
}
