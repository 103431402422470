import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { visuallyHidden } from "@mui/utils";
import {
  BuildingDevicesHostDataType,
  BuildingDevicesStructureDataType,
  DHCPDevicesStructureDataType,
  BuildingNameDataType,
} from "../../types/buildingTypes";
import DevicesHostsSearch from "./DevicesHostsSearch";
import TablePlaceholder from "../placeholders/TablePlaceholder";
import BuildingDevicesFilterMenu, {
  DEFAULT_HOSTS_FILTER_STATE,
} from "../filters/BuildingDevicesFilterMenu";
import DeviceDataGettingError from "../placeholders/DeviceDataGettingError";
import Button from "@mui/material/Button";
import { Link as RouterLink, useParams } from "react-router-dom";
import AdsClickOutlinedIcon from "@mui/icons-material/AdsClickOutlined";
import HostButtonTooltip from "../placeholders/HostButtonTooltip";
import s from "../placeholders/DeviceProcessingError.module.css";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (orderBy === "last_seen") {
    const dateA = new Date(a[orderBy] as string);
    const dateB = new Date(b[orderBy] as string);

    if (dateB < dateA) {
      return -1;
    }
    if (dateB > dateA) {
      return 1;
    }
    return 0;
  }

  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
  ignoreColumns: string[] = [],
): (
  a: { [key in Key]: number | string | boolean },
  b: { [key in Key]: number | string | boolean },
) => number {
  if (ignoreColumns.includes(orderBy as string)) {
    return () => 0;
  }

  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number,
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadHostsCell {
  disablePadding: boolean;
  id: keyof BuildingDevicesHostDataType;
  label: string;
  numeric: boolean;
}

const headHostsCells: readonly HeadHostsCell[] = [
  {
    id: "ip_address",
    numeric: false,
    disablePadding: false,
    label: "IP address",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "mac",
    numeric: false,
    disablePadding: false,
    label: "MAC address",
  },
  {
    id: "vendor",
    numeric: false,
    disablePadding: false,
    label: "Vendor",
  },
  {
    id: "device_type",
    numeric: false,
    disablePadding: false,
    label: "Device type",
  },
  {
    id: "model",
    numeric: false,
    disablePadding: false,
    label: "Model",
  },
  {
    id: "serial",
    numeric: false,
    disablePadding: false,
    label: "Serial",
  },
  {
    id: "switch_ip_address",
    numeric: false,
    disablePadding: false,
    label: "Switch ip address",
  },
  {
    id: "switch_port",
    numeric: false,
    disablePadding: false,
    label: "Switch port",
  },
  {
    id: "switch_name",
    numeric: false,
    disablePadding: false,
    label: "Switch name",
  },
  {
    id: "last_seen",
    numeric: false,
    disablePadding: false,
    label: "Last seen",
  },
];

interface HostsTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof BuildingDevicesHostDataType,
  ) => void;
  order: Order;
  orderBy: string;
}

function HostsTableHead(props: HostsTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof BuildingDevicesHostDataType) =>
    (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headHostsCells.map((HeadHostsCell) => (
          <TableCell
            key={HeadHostsCell.id}
            align={HeadHostsCell.numeric ? "right" : "left"}
            padding={HeadHostsCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === HeadHostsCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === HeadHostsCell.id}
              direction={orderBy === HeadHostsCell.id ? order : "asc"}
              onClick={createSortHandler(HeadHostsCell.id)}
            >
              {HeadHostsCell.label}
              {orderBy === HeadHostsCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface TableToolbarProps {
  title: string;
  search: boolean;
}

function TableToolbar(props: TableToolbarProps) {
  const { title, search } = props;
  const [filterInput, setFilterInput] = React.useState(
    DEFAULT_HOSTS_FILTER_STATE,
  );

  return (
    <Toolbar>
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h5"
        id="tableTitle"
        component="div"
      >
        {title}:
      </Typography>
      {search && <DevicesHostsSearch />}
      <BuildingDevicesFilterMenu
        filterState={filterInput}
        setFilterState={setFilterInput}
      />
    </Toolbar>
  );
}

interface BuildingDevicesHostsTableProps {
  hosts: BuildingDevicesHostDataType[] | [];
  cores: BuildingDevicesStructureDataType[] | [];
  distros: BuildingDevicesStructureDataType[] | [];
  accessSwitches: BuildingDevicesStructureDataType[] | [];
  selectedRegion: string | null;
  isLoading: boolean;
  apiErrorFlag: boolean;
}

export default function BuildingDevicesHostTable(
  props: BuildingDevicesHostsTableProps,
) {
  const {
    hosts,
    cores,
    distros,
    accessSwitches,
    selectedRegion,
    isLoading,
    apiErrorFlag,
  } = props;
  const { building } = useParams<BuildingNameDataType>();
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] =
    React.useState<keyof BuildingDevicesHostDataType>("last_seen");
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(30);
  const ignoreColumns = ["active"];

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof BuildingDevicesHostDataType,
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - hosts.length) : 0;

  const hostRows = React.useMemo(
    () =>
      stableSort(hosts, getComparator(order, orderBy, ignoreColumns)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, hosts],
  );

  const combinedSwitches: BuildingDevicesStructureDataType[] = [
    ...distros.map((distro) => {
      const entity = distro.role === "DHCP" ? "distro-dhcp" : "distro";
      return {
        ...distro,
        entity: entity,
        query: `${entity}/${distro.ip_address}?vendor=${distro.vendor}&site=${distro.name}&region=${selectedRegion}&building=${building}`,
      };
    }),
    ...accessSwitches.map((accessSwitch) => ({
      ...accessSwitch,
      entity: "switch",
      query: `switch/${accessSwitch.ip_address}?vendor=${accessSwitch.vendor}&site=${accessSwitch.name}&region=${selectedRegion}&building=${building}`,
    })),
  ];

  const combinedDHCPSwitches: DHCPDevicesStructureDataType[] = [
    ...cores.map((core) => ({
      ...core,
      entity: "core",
      query: `core/${core.ip_address}?region=${selectedRegion}&building=${building}`,
    })),
    ...distros.map((dhcpDistro) => ({
      ...dhcpDistro,
      entity: "distro",
      query: `distro-dhcp/${dhcpDistro.ip_address}?vendor=${dhcpDistro.vendor}&site=${dhcpDistro.name}&region=${selectedRegion}&building=${building}`,
    })),
  ];

  const findSwitchByIP = (switchIP: string): any => {
    return combinedSwitches.find(
      (relatedSwitch) => relatedSwitch.ip_address === switchIP,
    );
  };

  const dhcpDevicesIds = new Set(
    combinedDHCPSwitches
      .filter((dhcpSwitch) => dhcpSwitch.role === "DHCP")
      .map((dhcpSwitch) => dhcpSwitch.ip_address),
  );

  const coreDevicesIds = new Set(
    combinedDHCPSwitches
      .filter((dhcpSwitch) => dhcpSwitch.entity === "core")
      .map((dhcpSwitch) => dhcpSwitch.ip_address),
  );

  const showDhcpButton = (hostIp: string): boolean => {
    return !(dhcpDevicesIds.has(hostIp) || coreDevicesIds.has(hostIp));
  };

  const host_column_labels = headHostsCells.map((item) => item.label);
  const currentDHCP = combinedDHCPSwitches.find(
    (group) => group.role === "DHCP",
  );

  const getIpButtonTooltipContent = (
    dhcpEntity: string | undefined,
    dhcpIP: string | undefined,
    portIP: string,
  ): React.ReactNode => (
    <React.Fragment>
      <Typography variant="body2" className={s.host_header_tooltip}>
        Connect to{" "}
        <b>
          {dhcpEntity} ({dhcpIP})
        </b>{" "}
        and filter ARP table for <b>{portIP}</b>
      </Typography>
      <Divider className={s.host_divider_tooltip} />
      <Typography variant="body2" className={s.host_body_tooltip}>
        If an ARP entry is found, the host is receiving the proper IP address
        and should be reachable.
      </Typography>
      <Typography variant="body2" className={s.host_body_tooltip}>
        If an ARP entry is not found, the host is either disconnected from the
        switch, connected to the switch but on the wrong VLAN, or connected to
        the switch with a misconfigured IP address.
      </Typography>
    </React.Fragment>
  );

  const getL2MacButtonTooltipContent = (
    entity: string,
    switchIpAddress: string,
    mac: string,
  ): React.ReactNode => (
    <React.Fragment>
      <Typography variant="body2" className={s.host_header_tooltip}>
        Connect to{" "}
        <b>
          {entity} ({switchIpAddress})
        </b>{" "}
        and filter switchport table for <b>{mac}</b>
      </Typography>
      <Divider sx={{ margin: "2px 0" }} />
      <Typography variant="body2" className={s.host_body_tooltip}>
        If a switchport is found, the port is UP and learning the MAC address of
        the host, and the host is properly connected to the switch.
      </Typography>
      <Typography variant="body2" className={s.host_body_tooltip}>
        If a switchport is not found, the host is either disconnected from the
        switch or connected to a misconfigured port.
      </Typography>
    </React.Fragment>
  );

  const getL3MacButtonTooltipContent = (
    dhcpEntity: string | undefined,
    dhcpIP: string | undefined,
    mac: string,
  ): React.ReactNode => (
    <React.Fragment>
      <Typography variant="body2" className={s.host_header_tooltip}>
        Connect to{" "}
        <b>
          {dhcpEntity} ({dhcpIP})
        </b>{" "}
        and filter ARP table for <b>{mac}</b>
      </Typography>
      <Divider sx={{ margin: "2px 0" }} />
      <Typography variant="body2" className={s.host_body_tooltip}>
        If an ARP entry is found, the host is receiving an IP address, but the
        IP address may be different than what is shown in the host table.
      </Typography>
      <Typography variant="body2" className={s.host_body_tooltip}>
        If an ARP entry is not found, the host is either disconnected from the
        switch, connected to the switch but on the wrong VLAN, or connected to
        the switch with a misconfigured IP address.
      </Typography>
    </React.Fragment>
  );

  const getPortButtonTooltipContent = (
    entity: string | undefined,
    switchIpAddress: string | undefined,
    switchPort: string,
  ): React.ReactNode => (
    <React.Fragment>
      <Typography variant="body2" className={s.host_header_tooltip}>
        Connect to{" "}
        <b>
          {entity} ({switchIpAddress})
        </b>{" "}
        and filter switchport table for <b>{switchPort}</b>. The "Status" column
        will indicate whether the port is UP or DOWN.
      </Typography>
      <Divider sx={{ margin: "2px 0" }} />
      <Typography variant="body2" className={s.host_body_tooltip}>
        If the port is up, check the "VLAN" and MAC address" columns.
      </Typography>
      <Typography variant="body2" className={s.host_body_tooltip}>
        If the port is UP, on the correct VLAN, with the correct MAC address,
        then the host is properly connected to the switch.
      </Typography>
    </React.Fragment>
  );

  return (
    <Box sx={{ width: "100%" }} id={"hosts"}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableToolbar title={"Hosts"} search={true} />
        {isLoading ? (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <TablePlaceholder rows={10} columns={host_column_labels} />
          </Box>
        ) : (
          <TableContainer sx={{ minHeight: "30rem" }}>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <HostsTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {hostRows.map((row, index) => {
                  const relatedSwitch = findSwitchByIP(row.switch_ip_address);
                  const entityPath = relatedSwitch?.query;
                  const entity = relatedSwitch?.entity;
                  const showIpButton = showDhcpButton(row.ip_address);
                  const dhcpPath = currentDHCP?.query;
                  const dhcpEntity = currentDHCP?.entity;

                  const isL2ButtonVisible: boolean = (entityPath && relatedSwitch) && (row.mac != null);
                  const isL3ButtonVisible: boolean = showIpButton && row.mac != null;
                  const isPortButtonVisible: boolean = (entityPath && relatedSwitch) && (row.switch_port != null);

                  return (
                    <TableRow
                      hover
                      sx={{
                        cursor: "pointer",
                        whiteSpace: "nowrap",
                        backgroundColor: row.active ? "transparent" : "#fffde7",
                      }}
                    >
                      {showIpButton ? (
                        <TableCell>
                          <HostButtonTooltip
                            title={getIpButtonTooltipContent(
                              dhcpEntity,
                              currentDHCP?.ip_address,
                              row.ip_address,
                            )}
                            PopperProps={{
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, -9],
                                  },
                                },
                              ],
                            }}
                          >
                            <Button
                              component={RouterLink}
                              to={`/devices/${dhcpPath}&filter[ip_address]=${row.ip_address}`}
                              variant="outlined"
                              color={row.active ? "primary" : "inherit"}
                              endIcon={
                                <AdsClickOutlinedIcon fontSize="small" />
                              }
                            >
                              {row.ip_address}
                            </Button>
                          </HostButtonTooltip>
                        </TableCell>
                      ) : (
                        <TableCell>{row.ip_address}</TableCell>
                      )}
                      <TableCell>{row.name}</TableCell>
                      <TableCell>
                        {row.mac}
                        {isL2ButtonVisible && (
                          <HostButtonTooltip
                            title={getL2MacButtonTooltipContent(
                              entity,
                              row.switch_ip_address,
                              row.mac,
                            )}
                            PopperProps={{
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, -9],
                                  },
                                },
                              ],
                            }}
                          >
                            <Button
                              component={RouterLink}
                              to={`/devices/${entityPath}&filter[mac_address]=${row.mac}`}
                              variant="outlined"
                              color={row.active ? "primary" : "inherit"}
                              sx={{ marginLeft: 0.5, minWidth: "auto" }}
                            >
                              L2
                            </Button>
                          </HostButtonTooltip>
                        )}
                        {isL3ButtonVisible && (
                          <HostButtonTooltip
                            title={getL3MacButtonTooltipContent(
                              dhcpEntity,
                              currentDHCP?.ip_address,
                              row.mac,
                            )}
                            PopperProps={{
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, -9],
                                  },
                                },
                              ],
                            }}
                          >
                            <Button
                              component={RouterLink}
                              to={`/devices/${dhcpPath}&filter[mac_address]=${row.mac}`}
                              variant="outlined"
                              color={row.active ? "primary" : "inherit"}
                              sx={{ marginLeft: 0.5, minWidth: "auto" }}
                            >
                              L3
                            </Button>
                          </HostButtonTooltip>
                        )}
                      </TableCell>
                      <TableCell>{row.vendor}</TableCell>
                      <TableCell>{row.device_type}</TableCell>
                      <TableCell>{row.model}</TableCell>
                      <TableCell>{row.serial}</TableCell>
                      <TableCell>{row.switch_ip_address}</TableCell>
                      {isPortButtonVisible ? (
                        <TableCell>
                          <HostButtonTooltip
                            title={getPortButtonTooltipContent(
                              entity,
                              row.switch_ip_address,
                              row.switch_port,
                            )}
                            PopperProps={{
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, -9],
                                  },
                                },
                              ],
                            }}
                          >
                            <Button
                              component={RouterLink}
                              to={`/devices/${entityPath}&filter[port]=${row.switch_port}`}
                              variant="outlined"
                              color={row.active ? "primary" : "inherit"}
                              endIcon={
                                <AdsClickOutlinedIcon fontSize="small" />
                              }
                            >
                              {row.switch_port}
                            </Button>
                          </HostButtonTooltip>
                        </TableCell>
                      ) : (
                        <TableCell>{row.switch_port}</TableCell>
                      )}
                      <TableCell>{row.switch_name}</TableCell>
                      <TableCell>{row.last_seen}</TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
                {apiErrorFlag && (
                  <TableRow>
                    <TableCell colSpan={10}>
                      <DeviceDataGettingError entity={"hosts"} />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <TablePagination
          rowsPerPageOptions={[
            10,
            20,
            30,
            { value: hosts.length, label: "All" },
          ]}
          component="div"
          count={hosts.length}
          rowsPerPage={rowsPerPage}
          labelRowsPerPage="Rows per table:"
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </Box>
  );
}
