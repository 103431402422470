import React, { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { DurationEnum } from "../../types/analyticTypes";

type ActionDurationFilterProps = {
  option: DurationEnum;
  onOptionChange: (selectedOption: DurationEnum) => void;
};

const durationOptions = Object.keys(DurationEnum).map((item) => item);

const ActionDurationFilter: React.FC<ActionDurationFilterProps> = (props) => {
  const { option, onOptionChange } = props;
  const [selectedOption, setSelectedOption] = useState<DurationEnum>(option);

  const handleRoleChange = (event: SelectChangeEvent) => {
    setSelectedOption(event.target.value as DurationEnum);
    onOptionChange(event.target.value as DurationEnum);
  };

  return (
    <FormControl size="medium" style={{ minWidth: "100px" }}>
      <InputLabel>Duration</InputLabel>
      <Select
        value={selectedOption}
        label="duration"
        onChange={(e) => handleRoleChange(e)}
      >
        {durationOptions.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ActionDurationFilter;
