import React from "react";
import s from "./analytic.module.css";
import { Doughnut } from "react-chartjs-2";
import { utils_instance } from "../../utils/Utils";
import { DurationEnum } from "../../types/analyticTypes";

type ActionAnalyticsProps = {
  periodName: DurationEnum;
  dataSet: number[];
  labelSet: string[];
  sumOfActionCounts: number;
};

const ActionAnalytics: React.FC<ActionAnalyticsProps> = (props) => {
  const { periodName, dataSet, labelSet, sumOfActionCounts } = props;

  const chartDataType = {
    labels: labelSet,
    datasets: [
      {
        data: dataSet,
        backgroundColor: dataSet ? utils_instance.colorList : ["#D1D6DC"],
        cutout: "84%",
        display: true,
        border: "1px solid #D1D6DC",
        borderColor: "#D1D6DC",
      },
    ],
  };

  const totalAmount = (
    <div className={s.analytic_card_donut}>
      <p className={s.analytic_card_visit_number}>{sumOfActionCounts}</p>
      <p className={s.percentage}>Actions</p>
    </div>
  );

  const legend = labelSet.map((item, index) => {
    const backgroundColor = (): string => {
      if (item === "No actions") {
        return "#D1D6DC";
      } else {
        return utils_instance.colorList[
          index % utils_instance.colorList.length
        ];
      }
    };

    return (
      <div key={index} className={s.legend_line}>
        <div
          style={{ backgroundColor: backgroundColor() }}
          className={s.legend_color}
        />
        <p>
          {item}
          {dataSet[index] > 0 && " - "}
          {dataSet[index]}
        </p>
      </div>
    );
  });

  return (
    <>
      <div className={s.card_header}>Users' actions by type</div>
      <div className={`${s.item_2} ${s.grid_item}`} key={3}>
        <div className={s.resultDonut}>
          <div className={s.container}>
            <div className={s.graph}>
              <Doughnut
                className={s.doughnut}
                data={chartDataType}
                options={utils_instance.chartOptions}
              />
              {totalAmount}
            </div>
          </div>
        </div>
      </div>
      <div>
        <p>During last {periodName.toLowerCase()}:</p>
        <div className={s.legend_container}>{legend}</div>
      </div>
    </>
  );
};

export default ActionAnalytics;
