import { VendorType, ValidationGuideType } from "../types/types";

const INVALID_PORT_MESSAGE = "forbidden port";

const isValidVlan = (vlan: string) => {
  return vlan === "trunk";
};

const isValidCiscoPort = (port: string): boolean => {
  return (
    port.startsWith("Tw") || port.startsWith("Te") || port.startsWith("Gi")
  );
};

const isValidJuniperPort = (port: string): boolean => {
  return port.startsWith("ge-");
};

const isValidBrocadeRuckusPort = (port: string): boolean => {
  if (port.startsWith("lg") || port.startsWith("mgmt")) {
    return false;
  }

  const formatRegex = /^[0-9]+\/1\/[0-9]+$/;
  return formatRegex.test(port);
};

export const validatePort = (
  vendor: string | null,
  entity: { vlan: string; port: string },
): string[] => {
  const invalidReasons: string[] = [];

  if (isValidVlan(entity.vlan)) {
    invalidReasons.push("VLAN can't be trunk");
  }

  switch (vendor) {
    case VendorType.cisco:
      if (!isValidCiscoPort(entity.port)) {
        invalidReasons.push(INVALID_PORT_MESSAGE);
      }
      break;
    case VendorType.juniper:
      if (!isValidJuniperPort(entity.port)) {
        invalidReasons.push(INVALID_PORT_MESSAGE);
      }
      break;
    case VendorType.brocade:
    case VendorType.ruckus:
      if (!isValidBrocadeRuckusPort(entity.port)) {
        invalidReasons.push(INVALID_PORT_MESSAGE);
      }
      break;
    default:
      invalidReasons.push("unsupported vendor");
      break;
  }

  return invalidReasons;
};

export const validationGuide: ValidationGuideType[] = [
  {
    vendor: "juniper",
    prefixes: ["ge (e.g., ge-0/0/0, ge-0/0/11)"],
    vlan: "trunk",
    entity: "'isp' or 'distro'",
  },
  {
    vendor: "cisco",
    prefixes: [
      "Tw (e.g., Tw1/1/1, Tw7/7/7)",
      "Te (e.g., Te1/1/1, Te1/7/7)",
      "Gi (e.g., Gi1/1/1, Gi7/7/7)",
    ],
    vlan: "trunk",
  },
  {
    vendor: "brocade",
    prefixes: ["x/1/x (e.g., 1/1/1, 1/1/11)"],
    vlan: "trunk",
  },
  {
    vendor: "ruckus",
    prefixes: ["x/1/x (e.g., 1/1/1, 1/1/11)"],
    vlan: "trunk",
  },
];
