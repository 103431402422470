import { jwtDecode } from "jwt-decode";
import { ChartOptions } from "chart.js";

class Utils {
  checkIsValidToken = (token: string, cb: () => void) => {
    const decode: {
      exp: number;
    } = jwtDecode(token);
    if (decode.exp < +new Date() / 1000) {
      cb();
    }
  };

  colorList = [
    "#f44336",
    "#4caf50",
    "#607d8b",
    "#ff9800",
    "#2196f3",
    "#e91e63",
    "#9c27b0",
    "#673ab7",
    "#3f51b5",
    "#2196f3",
    "#009688",
    "#8bc34a",
    "#ffc107",
    "#795548",
    "#9e9e9e",
    "#607d8b",
  ];

  chartOptions: ChartOptions<"doughnut"> = {
    plugins: {
      legend: {
        position: "bottom",
        display: false,
      },
    },
  };

  chartDataActionInitialState = {
    labels: ["No actions"],
    datasets: [
      {
        data: [0, 100],
        backgroundColor: ["#D1D6DC"],
        cutout: "84%",
        display: true,
        border: "1px solid #D1D6DC",
        borderColor: "#D1D6DC",
        circumference: 360,
        rotation: 0,
      },
    ],
  };
}

export const utils_instance = new Utils();
