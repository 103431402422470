import React from "react";
import Header from "../modules/Header";
import { Box } from "@mui/material";
import AnnualActionAnalytic from "../charts/AnnualActionAnalytic";
import ActionAnalytics from "../charts/ActionAnalytics";
import Typography from "@mui/material/Typography";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ActionDurationFilter from "../filters/ActionDurationFilter";
import { DurationEnum } from "../../types/analyticTypes";
import { utils_instance } from "../../utils/Utils";
import { ArcElement } from "chart.js";
import Chart from "chart.js/auto";
import { ApiInstance } from "../../api/api";
import s from "../charts/analytic.module.css";

Chart.register(ArcElement);

function AnalyticPage() {
  const [periodName, setDurationOption] = React.useState<DurationEnum>(
    DurationEnum.Day,
  );
  const [actionData, setActionData] = React.useState<number[]>([]);
  const [actionLabels, setActionLabels] = React.useState<string[]>(
    utils_instance.chartDataActionInitialState.labels,
  );
  const [sumOfActionCounts, setSumOfActionCounts] = React.useState<number>(0);
  const handleDurationOptionChange = (selectedOption: DurationEnum) => {
    setDurationOption(selectedOption);
  };

  const loadData = async () => {
    setSumOfActionCounts(0);
    setActionData([]);
    setActionLabels(["No actions"]);

    console.log(periodName);
    console.log(DurationEnum.Year);

    if (periodName === DurationEnum.Year) {
      return null;
    }

    ApiInstance.getActionAnalytics(periodName.toLowerCase()).then((res) => {
      if (res.actions.length === 0) {
        return;
      } else {
        setSumOfActionCounts(
          res.actions.reduce((accumulator, currentValue) => {
            return accumulator + currentValue.action_count;
          }, 0),
        );
        const data: number[] = [];
        const labels: string[] = [];
        res.actions.map((item) => {
          data.push(item.action_count);
          labels.push(item.user_action);
        });
        setActionLabels(labels);
        setActionData(data);
      }
    });
  };

  React.useEffect(() => {
    loadData();
  }, [periodName]);

  return (
    <>
      <Header />
      {
        <Box sx={{ margin: "auto", width: "80%" }}>
          <Typography
            sx={{ flex: "1 1 100%" }}
            component="h1"
            variant="h4"
            align="center"
            margin={3}
          >
            <InfoOutlinedIcon fontSize="medium" color="primary" />
            Action analytics
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "10pt",
            }}
          >
            <ActionDurationFilter
              option={periodName}
              onOptionChange={handleDurationOptionChange}
            />
          </Box>
          {periodName === DurationEnum.Year ? (
            <AnnualActionAnalytic />
          ) : (
            <div className={`${s.paper_style}`} key={3}>
              <ActionAnalytics
                periodName={periodName}
                dataSet={actionData}
                labelSet={actionLabels}
                sumOfActionCounts={sumOfActionCounts}
              />
            </div>
          )}
        </Box>
      }
    </>
  );
}

export default AnalyticPage;
