import React from "react";
import Header from "../modules/Header";
import { Box } from "@mui/material";
import StackTable from "../modules/distro/StackTable";
import Typography from "@mui/material/Typography";
import useRegionQueryParam from "../../hooks/useRegionQueryParam";
import SwitchPortsTable from "../modules/distro/SwitchPortsTable";
import AccessSwitchTable from "../modules/access-switch/AccessSwitchTable";
import { useUserContext } from "../../context/userConext";

function AccessSwitchesPage() {
  const { selectedRegion } = useRegionQueryParam();
  const { user_permissions } = useUserContext();

  return (
    <>
      <Header />
      {
        <Box sx={{ margin: "auto", width: "80%" }}>
          <Typography
            sx={{ flex: "1 1 100%" }}
            component="h1"
            variant="h4"
            align="center"
            margin={3}
          >
            Access switch manager - Region: {selectedRegion}
          </Typography>
          <StackTable device={"switch"} />
          {user_permissions.port_bounce_action_permission ? (
            <AccessSwitchTable device={"switch"} />
          ) : (
            <SwitchPortsTable device={"switch"} />
          )}
        </Box>
      }
    </>
  );
}

export default AccessSwitchesPage;
