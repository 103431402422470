import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import WarningIcon from "@mui/icons-material/Warning";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { red } from "@mui/material/colors";
import { List, ListItem, ListItemText } from "@mui/material";
import { validationGuide } from "../../../utils/PortBounceValidation";

interface PortBounceValidationDialogProps {
  open: boolean;
  onClose: () => void;
  vendor: string | null;
  invalidPorts: string[];
}

const PortBounceValidationDialog: React.FC<PortBounceValidationDialogProps> = ({
  open,
  onClose,
  vendor,
  invalidPorts,
}) => {
  const currentRule = validationGuide.find((rule) => rule.vendor === vendor);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          minWidth: 600,
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.15)",
          borderRadius: "12px",
        },
      }}
    >
      <DialogTitle
        sx={{
          backgroundColor: red[400],
          color: "white",
          fontSize: "1.2rem",
          textAlign: "center",
          fontWeight: "bold",
          padding: "15px",
        }}
      >
        <WarningIcon
          sx={{
            fontSize: 40,
            color: "white",
            position: "absolute",
            left: 16,
            top: 8,
          }}
        />
        Bounce action not allowed
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "white",
            "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.1)" },
            "&:focus": {
              outline: "3px solid rgba(255, 61, 0, 0.6)",
              boxShadow: "0 0 0 4px rgba(255, 61, 0, 0.3)",
            },
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ padding: 3, textAlign: "center" }}>
        <Typography
          variant="h6"
          color="textSecondary"
          sx={{ fontWeight: "bold", paddingTop: "10px" }}
        >
          Bounce is allowed only for ports that meet the following conditions:
        </Typography>

        {currentRule ? (
          <List>
            <ListItem sx={{ py: 0 }}>
              <ListItemText
                primary={`1) The port name should start with one of the following prefix(es):`}
              />
            </ListItem>
            {currentRule.prefixes.map((prefix, index) => (
              <ListItem key={index} sx={{ pl: 10, py: 0 }}>
                <ListItemText primary={`- ${prefix}`} />
              </ListItem>
            ))}
            <ListItem>
              <ListItemText
                primary={`2) The VLAN of the selected port cannot be '${currentRule.vlan}'.`}
              />
            </ListItem>
            {currentRule.entity && (
              <ListItem sx={{ py: 0 }}>
                <ListItemText
                  primary={`3) Device hostname cannot contain ${currentRule.entity}.`}
                />
              </ListItem>
            )}
          </List>
        ) : (
          <Typography variant="body2">
            No rules found for {vendor} devices. Please contact the
            administrator for further assistance.
          </Typography>
        )}

        <Typography variant="body1" sx={{ marginTop: 2 }}>
          The following ports do not meet the requirements for{" "}
          <b>{vendor} devices</b> and must be deselected before continuing:
        </Typography>

        <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
          {invalidPorts.map((item, index) => (
            <li
              key={index}
              style={{ marginBottom: 4, color: red[500], fontWeight: "bold" }}
            >
              {item}
            </li>
          ))}
        </ul>

        <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2 }}>
          Please update your selection to proceed.
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default PortBounceValidationDialog;
