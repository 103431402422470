import * as React from "react";
import { Bar } from "react-chartjs-2";
import { ApiInstance } from "../../api/api";
import { utils_instance } from "../../utils/Utils";
import s from "./analytic.module.css";
import { ChartOptions } from "chart.js";
import {
  MonthlyAnalyticDataType,
  DataSetType,
} from "../../types/analyticTypes";

const MonthlyAnalyticDataTypeInitState = {
  month: "",
  action_counts: [
    {
      action: "",
      action_count: 0,
    },
  ],
};

type propsType = {};

const AnnualActionAnalytic: React.FC<propsType> = (props) => {
  const [labels, setLabels] = React.useState<string[]>([]);
  const [datasets, setDatasets] = React.useState<DataSetType>();
  const [actionData, setActionData] = React.useState([]);
  const [actionDataItem, setActionDataItem] =
    React.useState<MonthlyAnalyticDataType>(MonthlyAnalyticDataTypeInitState);
  const [isMouseOnBar, setIsMouseOnBar] = React.useState(false);

  React.useEffect(() => {
    ApiInstance.getAnnualAnalytic().then((res) => {
      setLabels(
        res.reverse().map((item: MonthlyAnalyticDataType) => item.month),
      );
      setActionData(res);
      const actionCounts: {
        [action: string]: number[];
      } = {};
      res.forEach((monthData: MonthlyAnalyticDataType) => {
        monthData.action_counts.forEach((actionData) => {
          const action = actionData.action;
          const count = actionData.action_count;

          if (!actionCounts[action]) {
            actionCounts[action] = [];
          }

          actionCounts[action].push(count);
        });
      });
      setDatasets(actionCounts);
    });
  }, []);

  const onBarHover = (event: any, activeElements: any) => {
    if (activeElements && activeElements.length > 0) {
      const hoveredIndex = activeElements[0].index;
      setActionDataItem(actionData[hoveredIndex]);
      setIsMouseOnBar(true);
    } else {
      setIsMouseOnBar(false);
    }
  };

  const options: ChartOptions<"bar"> = {
    aspectRatio: 3,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
    onHover: onBarHover,
    plugins: {
      legend: {
        position: "bottom",
        display: false,
      },
    },
  };

  const data = {
    labels,
    datasets: datasets
      ? Object.entries(datasets).map(([label, data], index) => ({
          label: label,
          data: data,
          backgroundColor: utils_instance.colorList[index],
        }))
      : [],
  };

  const legendIfMouseOver = datasets ? (
    Object.keys(datasets).map((item, index) => {
      const actionCount = datasets[item].reduce((sum, value) => sum + value, 0);
      const backgroundColor =
        utils_instance.colorList[index % utils_instance.colorList.length];
      return (
        actionCount !== 0 && (
          <div key={index} className={s.legend_line}>
            <div
              style={{ backgroundColor: backgroundColor }}
              className={s.legend_color}
            />
            <p style={{ marginTop: "0px" }}>
              {item} - {actionCount}
            </p>
          </div>
        )
      );
    })
  ) : (
    <></>
  );

  const legend = actionDataItem.action_counts.map((item, index) => {
    const backgroundColor =
      utils_instance.colorList[index % utils_instance.colorList.length];
    return (
      item.action_count !== 0 && (
        <div key={index} className={s.legend_line}>
          <div
            style={{ backgroundColor: backgroundColor }}
            className={s.legend_color}
          />
          <p style={{ marginTop: "0px" }}>
            {item.action} - {item.action_count}
          </p>
        </div>
      )
    );
  });

  const totalAmountYearly = () => {
    let totalAmount = 0;

    for (const action in datasets) {
      const actionCount = datasets[action].reduce(
        (sum, value) => sum + value,
        0,
      );
      totalAmount += actionCount;
    }
    return <p>Total action amount - {totalAmount}</p>;
  };

  const totalAmountMonthly = () => {
    let totalAmount = 0;

    for (const actionCount of actionDataItem.action_counts) {
      totalAmount += actionCount.action_count;
    }
    return <p>Total action amount - {totalAmount}</p>;
  };

  return (
    <div className={`${s.content} ${s.paper_style}`}>
      <div className={s.fields}>
        <div className={s.card_header}>Users' actions by type</div>
        <Bar options={options} data={data} />
      </div>
      <div>
        <p>{isMouseOnBar ? actionDataItem.month : "During last year:"}</p>
        <div className={s.legend_container}>
          {isMouseOnBar ? legend : legendIfMouseOver}
        </div>
        <div>{isMouseOnBar ? totalAmountMonthly() : totalAmountYearly()}</div>
      </div>
    </div>
  );
};

export default AnnualActionAnalytic;
