export enum RolesEnum {
  guest = "guest",
  ncc_user = "user",
  ncc_it = "ncc it",
  ncc_engineer = "engineer",
  ncc_admin = "admin",
}

export const RoleHierarchy: { [key in RolesEnum]: number } = {
  [RolesEnum.guest]: 1,
  [RolesEnum.ncc_user]: 2,
  [RolesEnum.ncc_it]: 3,
  [RolesEnum.ncc_engineer]: 4,
  [RolesEnum.ncc_admin]: 5,
};
