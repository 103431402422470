export enum Entities {
  buildings = "list of building",
  building_manager = "building manager",
  core_manager = "core manager",
  distro_manager = "distro manager",
  distro_dhcp_manager = "distro(DHCP) manager",
  access_manager = "access switch manager",
  user_management = "user list",
  action_analytics = "action analytics",
}

export enum VendorType {
  juniper = "juniper",
  cisco = "cisco",
  brocade = "brocade",
  ruckus = "ruckus",
}

export type ValidationGuideType = {
  vendor: string;
  prefixes: string[];
  vlan: string;
  entity?: string;
};

export type UserListDataType = {
  [email: string]: string[];
};

export type PortDataType = {
  port: string;
  vlan: string;
};

export type PerformPortBounceRequestType = {
  ip_address: string | undefined;
  device_type: string | null;
  region: string | null;
  building_code: string | null;
  unit: string | undefined;
  unit_name: string | null;
  ports: PortDataType[];
};
